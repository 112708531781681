import Dashboard from '../components/Dashboard/dashboard';
import Landing from '../components/Landing/landing';
import Login from '../components/Login/login';
import { useNavigate } from 'react-router-dom';
import Addproduct from '../components/product/addproduct';
import Disclaimers from '../components/product/disclaimers';
import howToAccessMembership from '../components/product/HowToAccessMembership';
import membershipBenefits from '../components/product/MembershipBenefits.jsx';
import planInformation from '../components/product/PlanInformation.jsx';
import summaryPlanDescription from '../components/product/SummaryPlanDescription.jsx';
import explanationOfBenefitsHistory from '../components/product/ExplanationOfBenefitsHistory.jsx';
import Editproduct from '../components/product/Editproduct.jsx';
import ScheduleCare from '../components/product/ScheduleCare';
import DigitizedIdCard from '../components/product/DigitizedIdCard';
import Faq from '../components/product/Faq';
import Editfaq from '../components/product/Editfaq';
import InsuranceCertification from '../components/product/InsuranceCertification';
import addclaims from '../components/product/addclaims.jsx';
import Claim from '../components/product/Claim';
import User from '../components/User/User';
import Notification from '../components/Notification/Notification';
import CreateNotification from '../components/Notification/CreateNotification';
import Groups from '../components/Groups/Groups';
import ManageMember from '../components/Groups/ManageMember';
import EditGroupProduct from '../components/Groups/EditGroupProduct';
import Usertemp from '../components/User/Usertemp';
import RedirectionUrl from "../components/Claim/RedirectionUrl.jsx"
import QuizComponent from '../components/Claim/QuizComponent.jsx';
import UpdateNotification from '../components/Notification/UpdateNotification.jsx';
import ClaimForm from '../components/Claim/ClaimForm.jsx';
import UpdateClaimForm from '../components/Claim/updateClaimForm.jsx';
import EditQuizForm from '../components/Claim/EditQuizForm.jsx';
import Report from '../components/Reports/Report.jsx';

const NoMatch = () => {
  const navigate = useNavigate()
  navigate("/dashboard")
};

export const routes = [
  {
    element: Landing,
    path: '/',
    isPrivate: false,
    isLayout: false,
  },
  {
    element: Dashboard,
    path: '/dashboard',
    isPrivate: true,
    isLayout: true,
  },
  {
    element: Login,
    path: '/login',
    isPrivate: false,
    isLayout: false,
  },
  {
    element: Addproduct,
    path: '/addproduct',
    isPrivate: true,
    isLayout: true,
  },
  {
    element: Disclaimers,
    path: '/disclaimers',
    isPrivate: true,
    isLayout: true,
  },
  {
    element: howToAccessMembership,
    path: '/access-membership',
    isPrivate: true,
    isLayout: true,
  },
  {
    element: membershipBenefits,
    path: '/membership-benefits',
    isPrivate: true,
    isLayout: true,
  },
  {
    element: planInformation,
    path: '/plan-information',
    isPrivate: true,
    isLayout: true,
  },
  {
    element: summaryPlanDescription,
    path: '/summary-plan-description',
    isPrivate: true,
    isLayout: true,
  },
  {
    element: explanationOfBenefitsHistory,
    path: '/benefits-history',
    isPrivate: true,
    isLayout: true,
  },
  {
    element: ScheduleCare,
    path: '/schedule-care',
    isPrivate: true,
    isLayout: true,
  },
  {
    element: Editproduct,
    path: '/edit-product',
    isPrivate: true,
    isLayout: true,
  },
  {
    element: DigitizedIdCard,
    path: '/digitized-id-card',
    isPrivate: true,
    isLayout: true,
  },
  {
    element: InsuranceCertification,
    path: '/insurance-certification',
    isPrivate: true,
    isLayout: true,
  },
  {
    element: addclaims,
    path: '/Add-claim',
    isPrivate: false,
    isLayout: true,
  },
  {
    element: Faq,
    path: '/faq',
    isPrivate: true,
    isLayout: true,
  },
  {
    element: Editfaq,
    path: '/edit-faq',
    isPrivate: true,
    isLayout: true,
  },
  {
    element: Claim,
    path: '/claim',
    isPrivate: true,
    isLayout: true,
  },

  {
    element: ClaimForm,
    path: '/claim-form',
    isPrivate: true,
    isLayout: true,
  },
  {
    element: UpdateClaimForm,
    path: '/edit-claim-form',
    isPrivate: true,
    isLayout: true,
  },

  {
    element: User,
    path: '/user',
    isPrivate: true,
    isLayout: true,
  },
  {
    element: Notification,
    path: '/notification',
    isPrivate: true,
    isLayout: true,
  },
  {
    element: CreateNotification,
    path: '/create-notification',
    isPrivate: true,
    isLayout: true,
  },
  {
    element: UpdateNotification,
    path: '/edit-notification',
    isPrivate: true,
    isLayout: true,
  },
  {
    element: Groups,
    path: '/groups',
    isPrivate: true,
    isLayout: true,
  },
  {
    element: ManageMember,
    path: '/manage-member',
    isPrivate: true,
    isLayout: true,
  },
  {
    element: EditGroupProduct,
    path: '/edit-group-product',
    isPrivate: true,
    isLayout: true,
  },
  {
    element: Usertemp,
    path: '/usertemp',
    isPrivate: true,
    isLayout: true,
  },
  {
    element: RedirectionUrl,
    path: '/redirection-url',
    isPrivate: true,
    isLayout: true,
  },
  {
    element: QuizComponent,
    path: '/quiz',
    isPrivate: true,
    isLayout: true,
  },
  {
    element: EditQuizForm,
    path: '/edit-quiz-form',
    isPrivate: true,
    isLayout: true,
  },
  {
    element: Report,
    path: '/reports',
    isPrivate: true,
    isLayout: true,
  },
  { path: '*', element: NoMatch },
];
