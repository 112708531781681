import { createButton } from 'react-simple-wysiwyg';
import { CiImageOn, CiTextAlignCenter, CiTextAlignLeft, CiTextAlignRight } from "react-icons/ci";
import { message } from 'antd';

// Create a button for aligning text to the center
const BtnAlignCenter = createButton('Align center', <CiTextAlignCenter />, 'justifyCenter');

// Create a button for aligning text to the left
const BtnAlignLeft = createButton('Align left', <CiTextAlignLeft />, 'justifyLeft');

// Create a button for aligning text to the right
const BtnAlignRight = createButton('Align right', <CiTextAlignRight />, 'justifyRight');

// Create a button for superscript
const BtnSuperscript = createButton('Superscript', 'x²', 'superscript');

// Create a button for subscript
const BtnSubscript = createButton('Subscript', 'x₂', 'subscript');

const BtnImageUpload = createButton('Upload Image', <CiImageOn />, () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileType = file.type;
            if (fileType.startsWith('image/')) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const img = document.createElement('img');
                    img.src = e.target.result;
                    document.execCommand('insertHTML', false, img.outerHTML);
                };
                reader.readAsDataURL(file);
            } else {
                message.error('Only image files are allowed. Please upload a valid image.');
            }
        }
    };
    input.click();
});

export { BtnAlignCenter, BtnAlignLeft, BtnAlignRight, BtnSuperscript, BtnSubscript, BtnImageUpload };
