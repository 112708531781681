// Main Group Listing Columns
export const groupsColumns = (navigate) => [
  {
    title: "Group Code",
    dataIndex: "groupCode",
    key: "groupCode",
    sorter: {
      compare: (a, b) => a.groupCode - b.groupCode,
    },
  },
  {
    title: "Group Name",
    dataIndex: "groupName",
    key: "groupName",
    sorter: {
      compare: (a, b) => a.groupName.localeCompare(b.groupName),
    },
  },
  {
    title: "Company Name",
    dataIndex: "companyName",
    key: "companyName",
    sorter: {
      compare: (a, b) => a.companyName.localeCompare(b.companyName),
    },
  },

  {
    title: "Product Code",
    dataIndex: "productCode",
    key: "productCode",
    sorter: {
      compare: (a, b) => a.productCode.localeCompare(b.productCode),
    },
  },
  {
    title: "Product Name",
    dataIndex: "productName",
    key: "productName",
    sorter: {
      compare: (a, b) => a.productName.localeCompare(b.productName),
    },
  },

  {
    title: "Action",
    key: "action",
    render: (key) => {
      return (
        <div className="action-icons">
          <div
          >
            <button className="manage" onClick={() => navigate('/manage-member', { state: key, groupId: localStorage.setItem("groupId", key?.groupId) })}>
              Manage
            </button>
          </div>
        </div>
      );
    },
  },
];


// Members Columns
export const membersColumns = [
  {
    title: "Member ID",
    dataIndex: "memberCode",
    key: "memberCode",
    sorter: {
      compare: (a, b) => a.memberCode - b.memberCode,
    },
  },
  {
    title: "Member Name",
    dataIndex: "memberName",
    key: "memberName",

    sorter: {
      compare: (a, b) => a.memberName - b.memberName,
      multiple: 3,
    },
  },
];

// Products Columns
export const manageGroupProductColumns = (navigate) => [
  {
    title: "Product Name",
    dataIndex: "productName",
    key: "productName",
    sorter: {
      compare: (a, b) => a.productName.localeCompare(b.productName),
    },
  },
  {
    title: "Product Code",
    dataIndex: "productCode",
    key: "productCode",
    sorter: {
      compare: (a, b) => a.productCode.localeCompare(b.productCode),
    },
  },
  {
    title: "Product Status",
    dataIndex: "productStatus",
    key: "productStatus",
    sorter: {
      compare: (a, b) => a.productStatus.localeCompare(b.productStatus),
    },
    render: (key) => {
      return (
        <div className={key === "Active" ? "activted" : "inactive"}>{key}</div>
      );
    },
  },
  {
    title: "Action",
    key: "action",
    render: (key) => {
      return (
        <div className="action-icons">
          <div
          >
            <button className="manage" onClick={() => navigate("/edit-group-product", { state: key, productId: localStorage.setItem("productId", key?.productId) })}>
              Manage
            </button>
          </div>
        </div>
      );
    },
  },
];