export const dashboardColumns = (navigate) => [
  {
    title: 'Product Name',
    dataIndex: 'productName',
    key: "productName",
    sorter: {
      compare: (a, b) => a.productName.localeCompare(b.productName),
    },
  },
  {
    title: 'Product Code',
    dataIndex: 'productCode',
    key: "productCode",
    sorter: {
      compare: (a, b) => a.productCode.localeCompare(b.productCode),
    },
  },
  {
    title: 'Product Status',
    dataIndex: 'productStatus',
    key: "productStatus",
    sorter: {
      compare: (a, b) => a.productStatus.localeCompare(b.productStatus),
      multiple: 3,
    },
    render: (key) => {
      return (
        <div className={key === "Active" ? "activted" : "inactive"}>{key}</div>
      );
    },

  },
  {
    title: 'Action',
    key: "action",
    render: (key) => {
      return (
        <div className="action-icons">
          <div
          >
            <button className="manage" onClick={() => { navigate("/edit-product", { state: key }); localStorage.setItem("productId", key?.productId) }}>
              Manage
            </button>
          </div>
        </div>
      );
    },
  },
];

