import React, { useContext, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { TimePicker } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { DatePicker, Space } from 'antd';
import CustomBreadcrumb from '../../common/CustomBreadcrumb';
import { pathName } from '../../utils/commonUtils';
import { SearchSelect } from '../../common/SearchSelect';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { getProductHandler } from '../../redux/action/getAllProductSlice';
import { getAllGroupsHandler } from '../../redux/action/getAllGroups';
import { getAllUserHandler } from '../../redux/action/getAllUserListSlice';
import {
  notificationHandler,
  notificationsSliceAction,
} from '../../redux/action/addNotificationsSlice';
import Loader from '../../components/Loder/loader';
import * as moment from 'moment';
import * as Yup from 'yup';
import { useCommonMessage } from '../../common/CommonMessage';
import { ThemeContext } from '../../common/themeContext';
import { createNotificationSchema, emailSchema, pushDescriptionSchema, smsSchema } from '../../common/Validations';

const CreateNotification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const messageApi = useCommonMessage();
  const { brandId } = useContext(ThemeContext);

  const [notificationType, setNotificationType] = useState('sms');
  const [selectLanguage, setSelectLanguage] = useState("english")
  const [getProduct, setGetProduct] = useState([]);
  const [groupsData, setGroupsData] = useState([]);
  const [userData, setUserData] = useState([]);
  const storedTheme = localStorage.getItem('theme');

  const getAllProductList = useSelector((state) => state?.getAllProductList);
  const getAllGroupsList = useSelector((state) => state?.getAllGroups);
  const getUserList = useSelector((state) => state?.getAllUserList);
  const getNotifications = useSelector(
    (state) => state?.notificationSliceDetails
  );

  useEffect(() => {
    handleProduct();
    handleGroup();
    handleMemberName();
  }, []);

  const smsData = Yup.object().shape({
    ...createNotificationSchema,
    ...smsSchema,
  });
  const emailData = Yup.object().shape({
    ...createNotificationSchema,
    ...emailSchema,
  });
  const pushDescriptionData = Yup.object().shape({
    ...createNotificationSchema,
    ...pushDescriptionSchema,
  });

  useEffect(() => {
    if (getNotifications?.data?.data) {
      messageApi.open({
        type: 'success',
        content: getNotifications?.data?.message,
      });

      setTimeout(() => {
        navigate('/notification');
      }, 1000);
      dispatch(notificationsSliceAction.notificationsDetailsSliceReset());
    }
  }, [getNotifications]);

  useEffect(() => {
    if (getAllProductList?.data?.data) {
      const productData = getAllProductList?.data?.data?.map((item) => ({
        productId: item?.id,
        productName: item?.name,
        code: item?.code,
      }));
      setGetProduct(productData);
    }

    if (getAllGroupsList?.data?.data) {
      const data = getAllGroupsList?.data?.data?.map((item) => {
        return {
          groupCode: item?.group?.id,
          groupName: item?.group?.name,
          caspioEmployerGroupCode: item?.group?.caspioEmployerGroupCode,
        };
      });
      setGroupsData(data);
    }

    if (getUserList?.data?.data) {
      const getUser = getUserList?.data?.data.map((item) => {
        return {
          firstName: item?.firstName,
          lastName: item?.lastName,
          userId: item?.userId,
          caspioUserId: item?.caspioUserId,
        };
      });
      setUserData(getUser);
    }
  }, [getAllProductList, getAllGroupsList, getUserList]);

  const handleProduct = () => {
    const payload = {
      offSet: 1,
      limit: 100,
      refBrandId: storedTheme === 'healthCare' ? 1 : 2,
    };
    dispatch(getProductHandler(payload));
  };

  const handleGroup = () => {
    const payload = {
      offSet: 1,
      limit: 100,
      brandId: storedTheme === 'healthCare' ? 1 : 2,
    };
    dispatch(getAllGroupsHandler(payload));
  };

  const handleMemberName = () => {
    const payload = {
      offSet: 1,
      limit: 100,
      refBrandId: storedTheme === 'healthCare' ? 1 : 2,
    };
    dispatch(getAllUserHandler(payload));
  };

  const getDefaultTime = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const formik = useFormik({
    initialValues: {
      productName: '',
      groupName: '',
      memberName: '',
      date: null,
      time: null,
      notificationType: 'sms',
      description: '',
      subject: '',
      body: '',
      title: '',
      pushDescription: '',
      language: "english"
    },
    validationSchema:
      notificationType === 'sms'
        ? smsData
        : notificationType === 'email'
          ? emailData
          : notificationType === 'pushNotification'
            ? pushDescriptionData
            : undefined,

    validate: values => {
      const errors = {};
      if (!values?.productName && !values?.groupName && !values?.memberName) {
        errors.selection = 'Please select at least one dropdown option';
      }
      return errors;
    },
    onSubmit: (value) => {
      const {
        productName,
        groupName,
        memberName,
        date,
        time,
        notificationType,
        description,
        subject,
        body,
        title,
        pushDescription,
      } = value;
      const payload = {
        notificationReceiver: {
          userIds: memberName
            ? userData
              .filter(
                (user) =>
                  `${user?.caspioUserId}` === memberName
              )
              .map((user) => user?.userId)
            : undefined,
          productId: productName
            ? getProduct
              .filter((product) => product?.code === productName)
              .map((product) => product?.productId)
            : undefined,
          groupId: groupName
            ? groupsData
              .filter((group) => group?.caspioEmployerGroupCode === groupName)
              .map((group) => group?.groupCode)
            : undefined,
        },
        eventType: brandId === 1 ? 'ADMIN_TYPE' : "ADMIN_TYPE_PC",
        notificationConfigJson: {
          sms: notificationType === 'sms',
          email: notificationType === 'email',
          pushNotification: notificationType === 'pushNotification',
        },
        scheduledDate: new Date(`${date} ${time}`).toISOString(),
        brandId: storedTheme === 'healthCare' ? 1 : 2,
        language: selectLanguage === "english" ? "en" : "es",

      };
      if (payload?.notificationConfigJson?.sms) {
        payload['sms'] = description;
      } else if (payload?.notificationConfigJson?.email) {
        payload['emailSubject'] = subject;
        payload['emailBody'] = body;
      } else if (payload?.notificationConfigJson?.pushNotification) {
        payload['pushNotificationTitle'] = title;
        payload['pushNotificationDescription'] = pushDescription;
      }
      dispatch(notificationHandler(payload));
    },
  });

  const showError = formik?.errors?.selection && !formik?.values?.productName && !formik?.values?.groupName && !formik?.values?.memberName;

  return (
    <>
      {getNotifications?.isLoading === true && (
        <Loader loaderTransForm="loaderTransForm" />
      )}
      <div className="rightside">
        <div className="rightcontent">
          <p>
            <strong>Notifications</strong>
          </p>
          <CustomBreadcrumb
            items={[
              { text: 'Home' },
              {
                text: 'Notifications',
                onClick: () => navigate('/notification'),
              },
              { text: pathName(location), active: true },
            ]}
          />
          {/* Create */}
          <form onSubmit={formik?.handleSubmit}>
            <div className="sectionpadding mb-30">
              <p className="mb-0 heedprodct">Create Notification</p>
              <hr />
              <div className="d-xxl-flex align-items-center mb-30">
                <div className="pe-xxl-2 me-xxl-2 selecttype selecttype">
                  <p className="labelp">Product Name*</p>
                  <SearchSelect
                    value={formik?.values?.productName || "Search for Product"}
                    options={getProduct.map((data) => {
                      return {
                        value: data?.code,
                        label: data?.productName + ' ' + `(${data?.code})`,
                      };
                    })}
                    onBlur={() => { }}
                    onChange={(value) => {
                      formik.setFieldValue('productName', value);
                      formik.setTouched(false);
                      formik.setFieldValue('groupName', null);
                      formik.setFieldValue('memberName', null);
                    }}
                    id="productName"
                    placeholder="Search for Group"
                  />
                  {showError && <div className="error">{formik?.errors?.selection}</div>}
                </div>
                <div className="pe-xxl-2 me-xxl-2">
                  <p className="my-3 mt-xxl-5"> OR</p>
                </div>
                <div className="pe-xxl-2 me-xxl-2 selecttype selecttype">
                  <p className="labelp">Select Group*</p>
                  <SearchSelect
                    value={formik?.values?.groupName || "Search for Group"}
                    options={groupsData.map((data) => {
                      return {
                        value: data?.caspioEmployerGroupCode,
                        label:
                          data?.groupName +
                          ' ' +
                          `(${data?.caspioEmployerGroupCode})`,
                      };
                    })}
                    onBlur={() => { }}
                    onChange={(value) => {
                      formik.setFieldValue('groupName', value);
                      formik.setTouched(false);
                      formik.setFieldValue('productName', null);
                      formik.setFieldValue('memberName', null);
                    }}
                    id={'groupName'}
                    placeholder="Search for Group"
                  />
                  {showError && <div className="error">{formik?.errors?.selection}</div>}
                </div>
                <div className="pe-xxl-2 me-xxl-2">
                  <p className="my-3 mt-xxl-5"> OR</p>
                </div>
                <div className="pe-xxl-2 me-xxl-2 selecttype selecttype">
                  <p className="labelp">Select Member*</p>
                  <SearchSelect
                    options={userData.map((data) => {
                      return {
                        value: data?.caspioUserId,
                        label:
                          data?.firstName +
                          ' ' +
                          data?.lastName +
                          ' ' +
                          `(${data?.caspioUserId})`,
                      };
                    })}
                    onBlur={() => { }}
                    value={formik?.values?.memberName || "Select Member"}
                    onChange={(value) => {
                      formik.setFieldValue('memberName', value);
                      formik.setTouched(false);
                      formik.setFieldValue('groupName', null);
                      formik.setFieldValue('productName', null);
                    }}
                    id={'memberName'}
                    placeholder="Select Member"
                  />
                  {showError && <div className="error">{formik?.errors?.selection}</div>}
                </div>
              </div>
              <div className="d-xxl-flex align-items-center mb-30">
                <div className="pe-xxl-2 me-xxl-2  mb-xxl-0 mb-3 selecttype">
                  <p className="labelp">Select Date*</p>
                  <Space direction="vertical" className="noicon">
                    <DatePicker
                      placeholder="YYYY-MM-DD"
                      format="YYYY-MM-DD"
                      onChange={(date, dateString) => {
                        formik.setFieldValue('date', dateString);
                      }}
                      disabledDate={(current) => {
                        return current && current < moment().startOf('day');
                      }}
                      allowClear={false}
                    />
                  </Space>
                  {formik?.touched?.date && formik?.errors?.date && (
                    <div style={{ color: 'red' }}>{formik?.errors?.date}</div>
                  )}
                </div>
                <div className="pe-xxl-2 me-xxl-4">
                  <p className="my-3 mt-xxl-5">&</p>
                </div>
                <div className="pe-xxl-2 me-xxl-2 mb-xxl-0 mb-3 selecttype">
                  <p className="labelp">Select time*</p>
                  <Space direction="vertical" className="noicon">
                    <TimePicker
                      format="HH:mm"
                      onChange={(time, timeString) => {
                        if (timeString) {
                          formik.setFieldValue('time', timeString);
                        } else {
                          formik.setFieldValue('time', getDefaultTime());
                        }
                      }}
                      allowClear={false}
                    />
                  </Space>
                  {formik?.touched?.time && formik?.errors?.time && (
                    <div style={{ color: 'red' }}>{formik?.errors?.time}</div>
                  )}
                </div>
              </div>
              <div className='seprator'></div>
              {/* Select Language */}
              <div className="">
                <p className="notification-type">
                  Select Language
                </p>
              </div>
              <div className="">
                <Form.Check
                  inline
                  type="radio"
                  label="English"
                  value="english"
                  name="english"
                  id="english"
                  className="custom-radio cursor-pointer"
                  checked={selectLanguage === "english"}
                  onChange={(e) => {
                    setSelectLanguage(e?.target?.value);
                    formik.setValues({ ...formik.values, selectLanguage: e?.target?.value });
                  }}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Spanish"
                  value="spanish"
                  name="spanish"
                  id="spanish"
                  className="custom-radio"
                  checked={selectLanguage === 'spanish'}
                  onChange={(e) => {
                    setSelectLanguage(e?.target?.value);
                    formik.setValues({ ...formik?.values, selectLanguage: e?.target?.value });
                  }}
                />
              </div>
              <div className='seprator'></div>
              {/* Select any one Notification Type */}
              <div className="">
                <p className="notification-type">
                  Select any one Notification Type
                </p>
              </div>
              <div className="">
                <Form.Check
                  inline
                  type="radio"
                  label="SMS"
                  value="sms"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  className="custom-radio cursor-pointer"
                  checked={notificationType === 'sms'}
                  onChange={(e) => {
                    setNotificationType(e?.target?.value);
                    formik.setValues({ ...formik.values, notificationType: e?.target?.value });
                  }}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Email"
                  value="email"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  className="custom-radio"
                  checked={notificationType === 'email'}
                  onChange={(e) => {
                    setNotificationType(e?.target?.value);
                    formik.setValues({ ...formik?.values, notificationType: e?.target?.value });
                  }}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Push Notification"
                  value="pushNotification"
                  name="inlineRadioOptions"
                  id="inlineRadio3"
                  className="custom-radio"
                  checked={notificationType === 'pushNotification'}
                  onChange={(e) => {
                    setNotificationType(e?.target?.value);
                    formik.setValues({ ...formik?.values, notificationType: e?.target?.value });
                  }}
                />
              </div>

              <div className="mt-4">
                {notificationType === 'sms' && (
                  <div className="mb-2 login-form">
                    <p className="labelp">Description*</p>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      placeholder="Description"
                      value={formik?.values?.description}
                      onChange={(e) =>
                        formik.setFieldValue('description', e?.target?.value)
                      }
                      maxLength={150}
                    />
                    {formik?.touched?.description &&
                      formik?.errors?.description && (
                        <div style={{ color: 'red' }}>
                          {formik?.errors?.description}
                        </div>
                      )}
                  </div>
                )}
                {notificationType === 'email' && (
                  <>
                    <div className="mb-2 login-form">
                      <p className="labelp">Subject*</p>
                      <Form.Control
                        className="selectdisplayed"
                        type="text"
                        placeholder="Subject"
                        value={formik?.values?.subject}
                        onChange={(e) =>
                          formik.setFieldValue('subject', e?.target?.value)
                        }
                        maxLength={100}
                      />
                      {formik?.touched?.subject && formik?.errors?.subject && (
                        <div style={{ color: 'red' }}>
                          {formik?.errors?.subject}
                        </div>
                      )}
                    </div>
                    <div className="mb-2 login-form">
                      <p className="labelp">Body*</p>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="Body"
                        value={formik?.values?.body}
                        onChange={(e) =>
                          formik.setFieldValue('body', e?.target?.value)
                        }
                        maxLength={250}
                      />
                      {formik?.touched?.body && formik?.errors?.body && (
                        <div style={{ color: 'red' }}>
                          {formik?.errors?.body}
                        </div>
                      )}
                    </div>
                  </>
                )}
                {notificationType === 'pushNotification' && (
                  <>
                    <div className="mb-2 login-form">
                      <p className="labelp">Title*</p>
                      <Form.Control
                        className="selectdisplayed"
                        type="text"
                        placeholder="Title"
                        value={formik?.values?.title}
                        onChange={(e) =>
                          formik.setFieldValue('title', e?.target?.value)
                        }
                        maxLength={60}
                      />
                      {formik?.touched?.title && formik?.errors?.title && (
                        <div style={{ color: 'red' }}>
                          {formik?.errors?.title}
                        </div>
                      )}
                    </div>
                    <div className="mb-2 login-form">
                      <p className="labelp">Description*</p>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="Description"
                        value={formik?.values?.pushDescription}
                        onChange={(e) =>
                          formik.setFieldValue(
                            'pushDescription',
                            e?.target?.value
                          )
                        }
                        maxLength={150}
                      />
                      {formik?.touched?.pushDescription &&
                        formik?.errors?.pushDescription && (
                          <div style={{ color: 'red' }}>
                            {formik?.errors?.pushDescription}
                          </div>
                        )}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="text-end mt-30 bgt">
              <button
                type="submit"
                className="sbg border-0 py3 fw-18 gbo continuebtn savebtn"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateNotification;
