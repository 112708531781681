import radioimg from "../../assets/radio.svg";
import checkimg from "../../assets/checkbox.svg";
import dropimg from "../../assets/dropdown.svg";

export const questionOption = [
  {
    value: "radio",
    label: (
      <div className="d-flex align-items-center">
        <img src={radioimg} className="me-2" alt="" />
        <span>Multiple Choice</span>
      </div>
    ),
  },
  {
    value: "checkBox",
    label: (
      <div className="d-flex align-items-center">
        <img src={checkimg} className="me-2" alt="" />
        <span>Checkboxes</span>
      </div>
    ),
  },
  {
    value: "dropDownSingle",
    label: (
      <div className="d-flex align-items-center">
        <img src={dropimg} className="me-2" alt="" />
        <span>Dropdown with Single Select</span>
      </div>
    ),
  },
  {
    value: "dropDownMulti",
    label: (
      <div className="d-flex align-items-center">
        <img src={dropimg} className="me-2" alt="" />
        <span>Dropdown with Multiple Select</span>
      </div>
    ),
  },
];

export const OPTION = [
    {
      value: '02',
      label: '02',
    },
    {
      value: '03',
      label: '03',
    },
    {
      value: '04',
      label: '04',
    },
    {
      value: '05',
      label: '05',
    },
    {
      value: '06',
      label: '06',
    },
    {
      value: '07',
      label: '07',
    },
    {
      value: '08',
      label: '08',
    },
    {
      value: '09',
      label: '09',
    },
    {
      value: '10',
      label: '10',
    },
    {
      value: '11',
      label: '11',
    },
    {
      value: '12',
      label: '12',
    },
  ]
