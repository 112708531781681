import React, { useEffect, useState } from "react";
import { Checkbox, Switch } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import CustomBreadcrumb from "../../common/CustomBreadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { getGroupProductFeatureHandler } from "../../redux/action/getGroupProductFeature";
import Loader from "../../components/Loder/loader"
import { updateGroupProductFeatureAction, updateGroupProductFeaturesHandler } from "../../redux/action/updateGroupProductFeature";
import { BreadCrum } from "../../common";

const EditGroupProduct = () => {
  const navigate = useNavigate();
  const [switchInfos, setSwitchInfos] = useState({});
  const [toggleSwitch, setToggleSwitch] = useState({})

  const getGroupProductFeatureSelector = useSelector((state) => state?.getGroupProductFeatureState)
  const updateGroupProductFeatureSelector = useSelector((state) => state?.updateGroupProductFeatureMain)

  const { state } = useLocation();

  const dispatch = useDispatch()

  const getNavigateUrl = (item) => {
    switch (item?.refFeatureId) {
      case 1:
        navigate("/disclaimers", { state: { fromGroupData: item, productId: getGroupProductFeatureSelector?.data?.data?.product?.id } });
        break;
      case 2:
        navigate("/access-membership", { state: { fromGroupData: item, productId: getGroupProductFeatureSelector?.data?.data?.product?.id } });
        break;
      case 3:
        navigate("/membership-benefits", { state: { fromGroupData: item, productId: getGroupProductFeatureSelector?.data?.data?.product?.id } });
        break;
      case 4:
        navigate("/plan-information", { state: { fromGroupData: item } });
        break;
      case 5:
        navigate("/summary-plan-description", { state: { fromGroupData: item } });
        break;
      case 6:
        navigate("/schedule-care", { state: { fromGroupData: item } });
        break;
      case 7:
        navigate("/insurance-certification", { state: { fromGroupData: item, productId: getGroupProductFeatureSelector?.data?.data?.product?.id } });
        break;
      case 8:
        navigate("/digitized-id-card", { state: { fromGroupData: item } });
        break;
      case 9:
        navigate("/claim", { state: { fromGroupData: item } });
        break;
      case 10:
        navigate("/faq", { state: { fromGroupData: item } });
        break;
      case 11:
        navigate("/benefits-history", { state: { fromGroupData: item } });
        break;
      default:
        navigate("/disclaimers");
        break;
    }
  };
  const productId = localStorage.getItem("productId")
  const groupId = localStorage.getItem("groupId")

  useEffect(() => {
    if (Number(productId) && Number(groupId)) {
      let payload = {
        productId: Number(productId),
        groupId: Number(groupId)
      }
      dispatch(getGroupProductFeatureHandler(payload))
    }

  }, [groupId, productId])

  useEffect(() => {
    if (updateGroupProductFeatureSelector?.data?.statusCode === 200) {
      let payload = {
        productId: Number(productId),
        groupId: Number(groupId)
      }
      dispatch(getGroupProductFeatureHandler(payload))
    }
  }, [updateGroupProductFeatureSelector])

  useEffect(() => {
    const newSwitchInfos = {};
    getGroupProductFeatureSelector?.data?.data?.groupProductFeatures?.forEach(element => {
      if (element.groupLevelModification === true) {
        newSwitchInfos[element.id] = true;
      } else {
        newSwitchInfos[element.id] = false;
      }
    });
    setSwitchInfos(newSwitchInfos);
  }, [getGroupProductFeatureSelector]);

  useEffect(() => {
    const toggleSwitchMain = {};
    getGroupProductFeatureSelector?.data?.data?.groupProductFeatures?.forEach(element => {
      if (element.status === "ACTIVE") {
        toggleSwitchMain[element.id] = true;
      } else {
        toggleSwitchMain[element.id] = false;
      }
    });
    setToggleSwitch(toggleSwitchMain);
  }, [getGroupProductFeatureSelector]);

  const handleCheckBoxChange = (item) => {
    setSwitchInfos(prevState => ({
      ...prevState,
      [item?.id]: !prevState[item?.id]
    }));
    if (item?.id) {
      let payload = {
        id: item?.id,
        groupLevelModification: item?.groupLevelModification === false ? true : item?.groupLevelModification === true ? false : ""
      }
      dispatch(updateGroupProductFeaturesHandler(payload))
    }
  }

  const handleSwitchChange = (item) => {
    setToggleSwitch(prevState => ({
      ...prevState,
      [item?.id]: !prevState[item?.id]
    }));
    let payload = {
      id: item?.id,
      status: item?.status === "ACTIVE" ? "INACTIVE" : item?.status === "INACTIVE" ? "ACTIVE" : ""
    }
    dispatch(updateGroupProductFeaturesHandler(payload))
  }

  useEffect(() => {
    if (updateGroupProductFeatureSelector?.data?.statusCode === 200) {
      dispatch(updateGroupProductFeatureAction.updateGroupProductFeatureInfoReset())
    }
  }, [updateGroupProductFeatureSelector])

  return (
    <>
      {(getGroupProductFeatureSelector?.isLoading === true || updateGroupProductFeatureSelector?.isLoading === true) && <Loader loaderTranForm="loaderTranForm" />}
      <div className="rightside">
        <div className="rightcontent">
          <p>
            <strong>Groups</strong>
          </p>

          <CustomBreadcrumb
            items={[
              { text: BreadCrum.HOME },
              { text: BreadCrum.GROUPS, onClick: () => navigate("/groups") },
              { text: BreadCrum.MANAGE_PRODUCTS, onClick: () => navigate("/manage-member", { state: { fromEditGroup: state, groupsDetails: getGroupProductFeatureSelector?.data || state?.groupCode || getGroupProductFeatureSelector } }) },
              { text: getGroupProductFeatureSelector?.data?.data?.product?.name, active: true },
            ]}
          />
          <div className="groupgrid mb-30">
            <div>
              <div className="headdetail">Product Details</div>
              <div className="sectionpadding mtm">
                <div className="d-flex justify-content-between align-items-center nogridwidth d-4grid">
                  <div>
                    <div className="productname mb-10">Product Name</div>
                    <div className="producttitle ">{getGroupProductFeatureSelector?.data?.data?.product?.name?.substring(0, 10)}..</div>
                  </div>
                  <div className="verticalline mx-35"></div>
                  <div className="">
                    <div className="productname mb-10">Product Code</div>
                    <div className="producttitle ">{getGroupProductFeatureSelector?.data?.data?.product?.code}</div>
                  </div>
                  <div className="verticalline mx-35"></div>
                  <div className="">
                    <div className="productname mb-10">Product Status</div>
                    <div className="producttitle activetext">{getGroupProductFeatureSelector?.data?.data?.product?.activeStatus}</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="headdetail">Product Status</div>
              <div className="sectionpadding mtm">
                <div className="d-flex justify-content-between align-items-center nogridwidth d-4grid">
                  <div>
                    <div className="productname mb-10">Group Code </div>
                    <div className="producttitle ">{getGroupProductFeatureSelector?.data?.data?.group?.caspioEmployerGroupCode || "N/A"}</div>
                  </div>
                  <div className="verticalline mx-35"></div>
                  <div className="">
                    <div className="productname mb-10">Group Name</div>
                    <div className="producttitle ">{getGroupProductFeatureSelector?.data?.data?.group?.name || "N/A"}</div>
                  </div>
                  <div className="verticalline mx-35"></div>
                  <div className="">
                    <div className="productname mb-10">Company Name</div>
                    <div className="producttitle activetext">{getGroupProductFeatureSelector?.data?.data?.companyName || "N/A"}</div>
                  </div>
                </div>
              </div>
            </div>
            {getGroupProductFeatureSelector?.data?.data?.product?.activeStatus === "ACTIVE" && getGroupProductFeatureSelector?.data?.data?.groupProductFeatures?.map((item) => {
              return (
                <>
                  <div>
                    <div className="sectionpadding" key={item?.id}>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="producttitle">{item?.featureName}</div>
                        <Switch className="me-3 switches" onChange={() => handleSwitchChange(item)} checked={toggleSwitch[item.id]} />
                      </div>
                      <hr className="my-20" />
                      {item?.customContent === true ? (
                        <div className="d-flex justify-content-between align-items-center groupcheck">
                          <div className="producttitle me-30 text-nowrap">
                            <label htmlFor="checking1">Custom Content</label>
                          </div>
                          <div className="d-flex justify-content-between align-items-center w-100 ">
                            <Checkbox onChange={() => handleCheckBoxChange(item)} defaultChecked={item?.groupLevelModification} disabled={item?.status === "INACTIVE" ? true : false}></Checkbox>
                            {switchInfos[item.id] && (
                              <button
                                className="manage"
                                onClick={() => getNavigateUrl(item)}
                                disabled={item?.status === "INACTIVE" ? true : false}
                              >
                                Manage
                              </button>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="notchange">
                          You cannot customize this feature.
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )
            })}
          </div>
          <div className="text-end">
            <button
              type="submit"
              onClick={() => navigate('/manage-member', { state: { fromEditGroup: state, groupsDetails: getGroupProductFeatureSelector?.data || state?.groupCode || getGroupProductFeatureSelector } })}
              className="  border-0 py3 fw-18 gbo ms-auto bgtransparent  addcreatebtn"
            >
              <span>Go back to listing</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditGroupProduct;
