import { Formik, Form, Field, ErrorMessage } from "formik";
import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProductFeatureContentHandler } from "../../redux/action/getProductFeatureContentSlice";
import { ThemeContext } from "../../common/themeContext";
import Loader from "../../components/Loder/loader";
import { addProductFeatureAction, addProductFeaturesHandler } from "../../redux/action/addProductFeaturesSlice";
import { handleInputChange, handleKeyDown, handleKeyPressSpace } from "../../common/CommonFunctions";
import CustomBreadcrumb from "../../common/CustomBreadcrumb";
import { BreadCrum } from "../../common";
import { useCommonMessage } from "../../common/CommonMessage";
import { getGroupProductFeatureContentHandler } from "../../redux/action/getGroupProductFeatureContent";
import { updateGroupProductFeatureAction, updateGroupProductFeaturesHandler } from "../../redux/action/updateGroupProductFeature";
import { fileUploadAction } from "../../redux/action/fileUpload";
import { validationSchemaScheduleCare } from "../../common/Validations";

const ScheduleCare = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { brandId } = useContext(ThemeContext)
  const messageApi = useCommonMessage();
  const { state } = useLocation()

  const getProductId = localStorage.getItem("productId")

  const getProductFeatureSelector = useSelector((state) => state?.getProductFeatureContentMain)
  const addProductFeaturesSelector = useSelector((state) => state?.addProductFeatures);
  const getGroupProductFeatureContent = useSelector((state) => state?.getGroupProductFeatureContent)
  const updateGroupProductFeatureSelector = useSelector((state) => state?.updateGroupProductFeatureMain)

  let getFeatureFromGroup = getGroupProductFeatureContent?.data?.data

  const handleFormSubmit = (values) => {
    if (values?.contact_number && !state?.fromGroupData?.id) {
      let payload = {
        productId: getProductFeatureSelector?.data?.data?.productId,
        featureId: getProductFeatureSelector?.data?.data?.refFeatureId,
        content: {
          name: "",
          number: values?.contact_number?.toString(),
          is_number: true,
        }
      }
      dispatch(addProductFeaturesHandler(payload))
    } else if (state?.fromGroupData?.id) {
      let payload = {
        id: getGroupProductFeatureContent?.data?.data?.id,
        content: {
          name: "",
          number: values?.contact_number?.toString(),
          is_number: true,
        }
      }
      dispatch(updateGroupProductFeaturesHandler(payload))
    }
  }

  // Get Product feature content and get group product feature content handler
  useEffect(() => {
    if (state?.fromGroupData?.id) {
      let payload1 = {
        groupProductFeatureId: state?.fromGroupData?.id
      }
      dispatch(getGroupProductFeatureContentHandler(payload1))
    } else if (!state?.fromGroupData) {
      let payload = {
        productId: state?.productId || state?.item?.productId,
        refFeatureId: state?.refFeatureId || state?.item?.refFeatureId
      }
      dispatch(getProductFeatureContentHandler(payload))
    }
  }, [brandId, state])

  useEffect(() => {
    if (addProductFeaturesSelector?.data) {
      messageApi.open({
        type: 'success',
        content: addProductFeaturesSelector?.data?.message,
      });
      setTimeout(() => {
        navigate("/addproduct", { state: addProductFeaturesSelector })
      }, 1000);
      dispatch(addProductFeatureAction.addProductFeaturesInfoReset())
    } else if (updateGroupProductFeatureSelector?.data) {
      messageApi.open({
        type: 'success',
        content: updateGroupProductFeatureSelector?.data?.message,
      });
      setTimeout(() => {
        navigate("/edit-group-product", { state: addProductFeaturesSelector })
      }, 1000);
      dispatch(updateGroupProductFeatureAction.updateGroupProductFeatureInfoReset())
    }
  }, [addProductFeaturesSelector, updateGroupProductFeatureSelector])

  return (
    <>
      {(getProductFeatureSelector?.isLoading === true || addProductFeaturesSelector?.isLoading === true || getGroupProductFeatureContent?.isLoading === true || updateGroupProductFeatureSelector?.isLoading === true) && <Loader loaderTransform="loaderTransform" />}
      <div className="rightside">
        <div className="rightcontent">
          <p>
            <strong>Manage Product</strong>
          </p>
          <CustomBreadcrumb
            items={[
              { text: BreadCrum.HOME },
              { text: getFeatureFromGroup?.groupLevelModification ? BreadCrum.GROUPS : BreadCrum.PRODUCTS, onClick: () => { navigate(getFeatureFromGroup?.groupLevelModification ? "/groups" : "/dashboard"); dispatch(fileUploadAction.fileUploadInfoReset()); localStorage.removeItem("productId") } },
              {
                text: state?.manageProduct || getFeatureFromGroup?.groupLevelModification ? BreadCrum.MANAGE_PRODUCTS : BreadCrum.ADD_PRODUCT, onClick: () => {
                  const path = state?.manageProduct
                    ? "/edit-product"
                    : getFeatureFromGroup?.groupLevelModification
                      ? "/edit-group-product"
                      : "/addproduct";
                  const navigationState = {
                    productId: state?.productId || Number(getProductId),
                    groupCode: state?.fromGroupData?.groupId
                  };
                  dispatch(fileUploadAction.fileUploadInfoReset())
                  navigate(path, { state: navigationState });
                }
              },
              ...(getFeatureFromGroup?.groupLevelModification && getFeatureFromGroup?.productName ? [{ text: getFeatureFromGroup.productName }] : []),
              { text: BreadCrum.CLICK_TO_SCHEDULE_CARE, active: true },
            ]}
          />
          <div className="login-form">
            <div className="table-padding mb-30">
              <div className="headtitle justify-content-start">
                <div className="lineafter">
                  <div className="productname">Product Name</div>
                  <div className="producttitle">{state?.productData?.productName || getProductFeatureSelector?.data?.data?.productName || getGroupProductFeatureContent?.data?.data?.productName}</div>
                </div>
                <p className="mb-0 heedprodct">Click to schedule care</p>
              </div>
            </div>
            <Formik
              enableReinitialize
              initialValues={{
                contact_number: (getProductFeatureSelector?.data?.data?.content?.number || getGroupProductFeatureContent?.data?.data?.content?.number) || ""
              }}
              validationSchema={validationSchemaScheduleCare}
              onSubmit={(values, formikBag) => {
                handleFormSubmit(values, formikBag);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="table-padding mb-40 p-30">
                    <div className="mb-0 heedprodct">Enter Contact Number</div>
                    <hr />
                    <div
                      className=" pe-2 position-relative"
                      controlId="formBasicEmail"
                    >
                      <label>Contact Number*</label>
                      <Field
                        type="number"
                        name="contact_number"
                        placeholder="Contact Number"
                        className="form-control w-md-50"
                        maxLength={15}
                        onKeyPress={handleKeyPressSpace}
                        onInput={handleInputChange}
                        onKeyDown={handleKeyDown}
                        autoComplete="off"
                      />
                      <ErrorMessage
                        name="contact_number"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>
                  <div className="text-end">
                    <button type="submit" className="sbg border-0  fw-18 gbo continuebtn savebtn">Save</button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScheduleCare;
