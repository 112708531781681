import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomBreadcrumb from "../../common/CustomBreadcrumb";
import { pathName } from "../../utils/commonUtils";
import { BreadCrum } from "../../common";
import { getNotificationsListsHandler } from "../../redux/action/getNotificationsListSlice";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "../../common/CommonTable";
import { ThemeContext } from "../../common/themeContext";
import Loader from "../../components/Loder/loader";
import { notificationColumns } from "./notificationUtils";

const Notification = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getNotification, setGetNotification] = useState([]);
  const notificationColumnsData = notificationColumns(navigate);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 100,
    showSizeChanger: true,
    pageSizeOptions: [5, 10, 15, 20],
    className: "order-pagination",
    locale: { items_per_page: "" },
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
  });

  const getAllNotificationsList = useSelector(
    (state) => state?.getNotificationsListDetails
  );

  const { brandId } = useContext(ThemeContext);

  useEffect(() => {
    if (getAllNotificationsList?.data?.data) {
      const productData = getAllNotificationsList?.data?.data?.map(
        ({
          id,
          deliveryStatus,
          notificationConfigJson,
          scheduledDate,
          sms,
          createdAt,
          pushNotificationTitle,
          pushNotificationDescription,
          brandId,
          emailSubject,
          emailBody,
          language,
          notificationReceiver,
        }) => ({
          id: id,
          deliveryStatus: deliveryStatus,
          createDate: createdAt,
          notificationConfigJson: notificationConfigJson,
          scheduledDate: scheduledDate,
          sms: sms,
          pushNotificationTitle: pushNotificationTitle,
          pushNotificationDescription: pushNotificationDescription,
          brandId: brandId,
          emailSubject: emailSubject,
          emailBody: emailBody,
          language: language === "en" ? "English" : "Spanish",
          notificationReceiver: {
            productId: notificationReceiver?.productId || null,
            userId: notificationReceiver?.userIds || null,
            groupId: notificationReceiver?.groupId || null,
          },
        })
      );
      setGetNotification(productData);
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: getAllNotificationsList?.data?.totalElements,
        current: getAllNotificationsList?.data?.pageNumber,
        pageSize: getAllNotificationsList?.data?.pageSize,
      }));
    }
  }, [getAllNotificationsList]);

  const handleProductData = (offset = 1, limit = 5) => {
    const payload = {
      offset,
      limit,
      brandId: brandId,
    };
    if (brandId === 1 || brandId === 2) {
      dispatch(getNotificationsListsHandler(payload));
    }
  };

  useEffect(() => {
    handleProductData();
  }, [brandId]);

  return (
    <div className="rightside">
      {getAllNotificationsList?.isLoading === true && (
        <Loader loaderTransForm="loaderTransForm" />
      )}
      <div className="rightcontent">
        <p>
          <strong>Notifications</strong>
        </p>
        <CustomBreadcrumb
          items={[
            { text: BreadCrum.HOME },
            { text: pathName(location), active: true },
          ]}
        />
        <div className="table-padding">
          <div className="headtitle">
            <p className="mb-0 heedprodct">Notifications</p>
            <Link className="primarybtn" to="/create-notification">
              <div className="plus"></div>
              Create
            </Link>
          </div>
          <hr className="m-0" />
          <div className="table-responsive">
            <CommonTable
              handlePageChange={handleProductData}
              pagination={pagination}
              body={getNotification}
              columns={notificationColumnsData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
