import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { dashboardColumns } from "./dashboardUtils";
import CustomBreadcrumb from "../../common/CustomBreadcrumb";
import CommonTable from "../../common/CommonTable";
import { useDispatch, useSelector } from "react-redux";
import { getProductHandler } from "../../redux/action/getAllProductSlice";
import { ThemeContext } from "../../common/themeContext";
import { addProductAction } from "../../redux/action/addProductSlice";
import { getProductFeaturesAction } from "../../redux/action/getproductFeaturesSlice";
import Loader from "../Loder/loader";

const Dashboard = () => {
  const [getProduct, setGetProduct] = useState([])
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 100,
    showSizeChanger: true,
    pageSizeOptions: [5, 10, 15, 20],
    className: "order-pagination",
    locale: { items_per_page: "" },
    showTotal: (total, range) =>
      `${range[0]}-${range[1]} of ${total}`,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const dashBoardColumnsData = dashboardColumns(navigate)

  const getAllProductList = useSelector((state) => state?.getAllProductList)
  const { brandId, isLoading } = useContext(ThemeContext);

  const handleProductData = (offSet = 1, limit = 10) => {
    window.scrollTo(0, 0);
    const payload = { offSet, limit, refBrandId: brandId };
    if (brandId === 1 || brandId === 2) {
      dispatch(getProductHandler(payload));
    }
  }

  useEffect(() => {
    handleProductData()
  }, [brandId]);

  useEffect(() => {
    const productList = getAllProductList?.data?.data;
    if (productList) {
      const productData = productList?.map(({ id, name, activeStatus, code }) => ({
        productId: id,
        productName: name,
        productStatus: activeStatus.charAt(0).toUpperCase() + activeStatus?.slice(1).toLowerCase(),
        productCode: code,
      }));
      setGetProduct(productData);
      const { totalElements, pageNumber, pageSize } = getAllProductList?.data;
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: totalElements,
        current: pageNumber,
        pageSize: pageSize,
      }));
    }
  }, [getAllProductList]);
  
  const addProduct = () => {
    navigate('/addproduct')
    dispatch(addProductAction.addProductSliceInfoReset());
    dispatch(getProductFeaturesAction.getProductFeaturesInfoReset())
    localStorage.removeItem("productId")
  }

  return (
    <>
      {(getAllProductList?.isLoading === true || isLoading === true) && <Loader loaderTransform="loaderTransform" />}
      <div className="rightside">
        <div className="rightcontent">
          <p>
            <strong>Products</strong>
          </p>
          <CustomBreadcrumb
            items={[
              { text: "Home" },
              { text: "Products", active: true },
            ]}
          />

          <div className="table-padding">
            <div className="headtitle">
              <p className="mb-0 heedprodct">Products</p>
              <button className="primarybtn" onClick={addProduct}>
                <div className="plus"></div>
                Add
              </button>
            </div>
            <hr className="m-0" />
            <div className="table-responsive">
              <CommonTable
                handlePageChange={handleProductData}
                pagination={pagination}
                body={getProduct}
                columns={dashBoardColumnsData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
