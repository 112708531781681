import { addProductFeaturesHandler } from "../../redux/action/addProductFeaturesSlice";
import { fileUploadAction } from "../../redux/action/fileUpload";
import { updateGroupProductFeaturesHandler } from "../../redux/action/updateGroupProductFeature";

// Product dragger handleSubmit payload
 export const DraggerPayload = ({ manageSwitch, getProductData, fileUploadData, values, plainText, fileUploaded, plainTextSpanish, spanishFileUpload,dispatch }) => {
    if (manageSwitch === false) {
        let payload = {
            productId: getProductData?.productId,
            featureId: getProductData?.refFeatureId,
            content: {
                pdf_url: fileUploadData ? true : false,
                html: values?.html_editor && !fileUploadData ? true : false,
                english: {
                    html: {
                        isTrue: !fileUploadData?.preSignedUrl && values?.html_editor ? true : false,
                        html_content: !fileUploadData?.preSignedUrl ? plainText : "" || !fileUploadData?.preSignedUrl ? getProductData?.content?.english?.html?.html_content : ""
                    },
                    file: {
                        name: fileUploadData ? fileUploaded : "",
                        isTrue: fileUploadData ? true : false,
                        url: fileUploadData?.signedUrl || "",
                        preSigned: fileUploadData?.preSignedUrl || ""
                    }
                },
                spanish: {
                    html: {
                        isTrue: (!fileUploadData?.preSignedUrl && getProductData?.content?.spanish?.html?.isTrue === true) || false,
                        html_content: !fileUploadData?.preSignedUrl ? getProductData?.content?.spanish?.html?.html_content : ""
                    },
                    file: {
                        name: (fileUploadData?.preSignedUrl && getProductData?.content?.spanish?.file?.name) || "",
                        isTrue: (fileUploadData?.preSignedUrl && getProductData?.content?.spanish?.file?.isTrue) || false,
                        url: (fileUploadData?.preSignedUrl && getProductData?.content?.spanish?.file?.url) || "",
                        preSigned: (fileUploadData?.preSignedUrl && getProductData?.content?.spanish?.file.preSigned) || ""
                    }
                }
            }
        }
        dispatch(addProductFeaturesHandler(payload))
        dispatch(fileUploadAction.fileUploadInfoReset())
    } else if (manageSwitch === true) {
        let payload = {
            productId: getProductData?.productId,
            featureId: getProductData?.refFeatureId,
            content: {
                pdf_url: fileUploadData ? true : false,
                html: values?.html_editor_spanish && !fileUploadData ? true : false,
                english: {
                    html: {
                        isTrue: (!fileUploadData?.preSignedUrl && getProductData?.content?.english?.file?.html?.isTrue === true) || (!fileUploadData?.preSignedUrl && values?.html_editor_spanish) ? true : false,
                        html_content: (!fileUploadData?.preSignedUrl && getProductData?.content?.english?.html?.html_content) || ""
                    },
                    file: {
                        name: (fileUploadData?.preSignedUrl && getProductData?.content?.english?.file?.name) || "",
                        isTrue: (fileUploadData?.preSignedUrl && getProductData?.content?.english?.file?.isTrue) || false,
                        url: (fileUploadData?.preSignedUrl && getProductData?.content?.english?.file?.url) || "",
                        preSigned: (fileUploadData?.preSignedUrl && getProductData?.content?.english?.file?.preSigned) || ""
                    }
                },
                spanish: {
                    html: {
                        isTrue: !fileUploadData?.preSignedUrl ? true : false || !fileUploadData?.preSignedUrl ? getProductData?.content?.spanish?.html?.isTrue : false,
                        html_content: !fileUploadData?.preSignedUrl ? plainTextSpanish : "" || !fileUploadData?.preSignedUrl ? getProductData?.content?.spanish?.html?.html_content : ""
                    },
                    file: {
                        url: fileUploadData?.signedUrl || "",
                        preSigned: fileUploadData?.preSignedUrl,
                        name: fileUploadData ? spanishFileUpload : "",
                        isTrue: fileUploadData ? true : false
                    }
                }
            }
        }
        dispatch(addProductFeaturesHandler(payload))
        dispatch(fileUploadAction.fileUploadInfoReset())
    }
}

// Group handleSubmit payload
export const GroupDraggerPayload = ({manageSwitch,getProductDataGroup,fileUploadData,values,plainText,fileUploaded,dispatch,plainTextSpanish,spanishFileUpload}) =>{
    if (manageSwitch === false) {
        let payload = {
            id: getProductDataGroup?.id,
            content: {
                pdf_url: fileUploadData ? true : false,
                html: values?.html_editor && !fileUploadData ? true : false,
                english: {
                    html: {
                        isTrue: !fileUploadData?.preSignedUrl && plainText ? true : false || !fileUploadData?.preSignedUrl ? getProductDataGroup?.content?.english?.file?.html?.isTrue : false,
                        html_content: !fileUploadData?.preSignedUrl ? plainText : "" || !fileUploadData?.preSignedUrl ? getProductDataGroup?.content?.english?.html?.html_content : ""
                    },
                    file: {
                        name: fileUploadData ? fileUploaded : "",
                        isTrue: fileUploadData ? true : false,
                        url: fileUploadData?.signedUrl || "",
                        preSigned: fileUploadData?.preSignedUrl
                    }
                },
                spanish: {
                    html: {
                        isTrue: (!fileUploadData?.preSignedUrl && getProductDataGroup?.content?.spanish?.html?.isTrue) || false,
                        html_content: (!fileUploadData?.preSignedUrl && getProductDataGroup?.content?.spanish?.html?.html_content) || ""
                    },
                    file: {
                        name: getProductDataGroup?.content?.spanish?.file?.name || "",
                        isTrue: getProductDataGroup?.content?.spanish?.file?.isTrue || false,
                        url: getProductDataGroup?.content?.spanish?.file?.signedUrl || "",
                        preSigned: getProductDataGroup?.content?.spanish?.preSigned
                    }
                }
            }
        }
        dispatch(updateGroupProductFeaturesHandler(payload))
        dispatch(fileUploadAction.fileUploadInfoReset())
    } else if (manageSwitch === true) {
        let payload = {
            id: getProductDataGroup?.id,
            content: {
                pdf_url: fileUploadData?.preSignedUrl ? true : false,
                html: values?.html_editor_spanish && !fileUploadData ? true : false,
                english: {
                    html: {
                        isTrue: (!fileUploadData?.preSignedUrl && getProductDataGroup?.content?.english?.html?.isTrue === true) || (!fileUploadData?.preSignedUrl && values?.html_editor_spanish) ? true : false,
                        html_content: (!fileUploadData?.preSignedUrl && getProductDataGroup?.content?.english?.html?.html_content) || ""
                    },
                    file: {
                        name: getProductDataGroup?.content?.english?.file?.name || "",
                        isTrue: getProductDataGroup?.content?.english?.file?.isTrue || false,
                        url: getProductDataGroup?.content?.english?.file?.signedUrl || "",
                        preSigned: getProductDataGroup?.content?.english?.file?.preSigned
                    }
                },
                spanish: {
                    html: {
                        isTrue: !fileUploadData?.preSignedUrl ? true : !fileUploadData?.preSignedUrl ? getProductDataGroup?.content?.spanish?.html?.isTrue : false,
                        html_content: !fileUploadData?.preSignedUrl ? plainTextSpanish : "" || !fileUploadData?.preSignedUrl ? getProductDataGroup?.content?.spanish?.html?.html_content : ""
                    },
                    file: {
                        url: fileUploadData?.signedUrl || "",
                        preSigned: fileUploadData?.preSignedUrl,
                        name: fileUploadData ? spanishFileUpload : "",
                        isTrue: fileUploadData?.preSignedUrl ? true : false
                    }
                }
            }
        }
        dispatch(updateGroupProductFeaturesHandler(payload))
        dispatch(fileUploadAction.fileUploadInfoReset())
    }
}
