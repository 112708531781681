import { addProductFeaturesHandler } from "../../redux/action/addProductFeaturesSlice";
import { fileUploadAction } from "../../redux/action/fileUpload";
import { getGroupProductFeatureContentAction } from "../../redux/action/getGroupProductFeatureContent";
import { getProductFeaturesAction } from "../../redux/action/getproductFeaturesSlice";
import { updateGroupProductFeaturesHandler } from "../../redux/action/updateGroupProductFeature";

// Coi StateWise For Products

export const handleSubmitCoiStateWise = ({
  manageSwitch,
  values,
  messageApi,
  state,
  fileUploadUrl,
  fileUploadUrlPreSignedUrl,
  spanish,
  dispatch,
  english,
}) => {
  const bothValue =
    manageSwitch === false
      ? values?.englishCertificate
      : values?.spanishCertificate;

  const invalidQuestions = bothValue.filter((item) => {
    return item?.selectedStates;
  });

  if (invalidQuestions[0]?.selectedStates?.length === 0) {
    messageApi.open({
      type: "warning",
      content: "Please Add options..!",
    });
  }
  if (manageSwitch === false) {
    let payload = {
      productId: state?.productId || state?.item?.productId,
      featureId: state?.refFeatureId || state?.item?.refFeatureId,
      content: {
        coi: true,
        english: {
          coi: {
            state: values?.englishCertificate?.map((item, index) => {
              return {
                url: item?.url || fileUploadUrl[index],
                fileName: item?.fileName || item?.file?.name,
                stateName: item?.selectedStates || item?.stateName,
                preSigned: item?.preSigned || fileUploadUrlPreSignedUrl[index],
              };
            }),
          },
        },
        spanish: spanish,
        isAllState: false,
      },
    };
    dispatch(addProductFeaturesHandler(payload));
    dispatch(fileUploadAction.fileUploadInfoReset());
    dispatch(getProductFeaturesAction.getProductFeaturesInfoReset());
  } else if (manageSwitch === true) {
    let payload = {
      productId: state?.productId || state?.item?.productId,
      featureId: state?.refFeatureId || state?.item?.refFeatureId,
      content: {
        coi: true,
        spanish: {
          coi: {
            state: values?.spanishCertificate?.map((item, index) => {
              return {
                url: item?.url || fileUploadUrl[index],
                fileName: item?.fileName || item?.file?.name,
                stateName: item?.selectedStates || item?.stateName,
                preSigned: item?.preSigned || fileUploadUrlPreSignedUrl[index],
              };
            }),
          },
        },
        english: english,
        isAllState: false,
      },
    };
    dispatch(addProductFeaturesHandler(payload));
    dispatch(fileUploadAction.fileUploadInfoReset());
    dispatch(getProductFeaturesAction.getProductFeaturesInfoReset());
  }
};

// Coi StateWise For Groups
export const handleSubmitCoiStateWiseGroup = ({
  manageSwitch,
  values,
  messageApi,
  getGroupProductFeatureContent,
  fileUploadUrl,
  fileUploadUrlPreSignedUrl,
  spanish,
  dispatch,
  english,
}) => {
  const bothValue =
    manageSwitch === false
      ? values?.englishCertificate
      : values?.spanishCertificate;
  const invalidQuestions = bothValue.filter((item) => {
    return item?.selectedStates;
  });

  if (invalidQuestions[0]?.selectedStates?.length === 0) {
    messageApi.open({
      type: "warning",
      content: "Please Add options..!",
    });
  }

  if (manageSwitch === false) {
    let payload = {
      id: getGroupProductFeatureContent?.data?.data?.id,
      content: {
        coi: true,
        english: {
          coi: {
            state: values?.englishCertificate?.map((item, index) => {
              return {
                url: item?.url || fileUploadUrl[index],
                fileName: item?.fileName || item?.file?.name,
                stateName: item?.selectedStates || item?.stateName,
                preSigned: item?.preSigned || fileUploadUrlPreSignedUrl[index],
              };
            }),
          },
        },
        spanish: spanish,
        isAllState: false,
      },
    };
    dispatch(updateGroupProductFeaturesHandler(payload));
    dispatch(fileUploadAction.fileUploadInfoReset());
    dispatch(
      getGroupProductFeatureContentAction.getGroupProductFeatureContentReset()
    );
  } else if (manageSwitch === true) {
    let payload = {
      id: getGroupProductFeatureContent?.data?.data?.id,
      content: {
        coi: true,
        spanish: {
          coi: {
            state: values?.spanishCertificate?.map((item, index) => {
              return {
                url: item?.url || fileUploadUrl[index],
                fileName: item?.fileName || item?.file?.name,
                stateName: item?.selectedStates || item?.stateName,
                preSigned: item?.preSigned || fileUploadUrlPreSignedUrl[index],
              };
            }),
          },
        },
        english: english,
        isAllState: false,
      },
    };
    dispatch(updateGroupProductFeaturesHandler(payload));
    dispatch(fileUploadAction.fileUploadInfoReset());
    dispatch(
      getGroupProductFeatureContentAction.getGroupProductFeatureContentReset()
    );
  }
};
