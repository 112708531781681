import { Table } from "antd";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const CommonTable = ({
  handlePageChange,
  pagination,
  body,
  columns,
  isPagination = true,
}) => {

  const customItemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return <LeftOutlined />;
    }
    if (type === 'next') {
      return <RightOutlined />;
    }
    return null;
  };
  return (
    <>
      <Table
        className="table-layout"
        columns={columns}
        dataSource={body}
        rowClassName="comman-table-row"
        pagination={
          isPagination
            ? {
              ...pagination,
              onChange: handlePageChange,
              itemRender: customItemRender,
              showLessItems: true,
            }
            : false
        }
        sortDirections={["ascend", "descend", "ascend"]}
        showSorterTooltip={false}
      />
    </>
  );
};

export default CommonTable;
